<template>
  <div>
    <!-- 标题 -->
    <div class="page-title">外延衬底管理</div>
    <!-- 筛选项 -->
    <div style="float:left;margin-top: 1rem;width: 100%">
      <el-col :span="24">
        <el-tooltip content="入库日期" placement="bottom" transition="none" effect="light" style="width: 300px">
          <el-date-picker
            v-model="screen.入库日期"
            type="daterange"
            range-separator="至"
            placeholder="入库日期"
            size="mini"
          >
          </el-date-picker>
        </el-tooltip>
        <el-button type="primary" plain @click="GetData(1)" size="mini">开始筛选</el-button>
        <el-button type="primary" plain @click="inLayer=step1=true" style="margin-left:1rem" size="mini">导入衬底
        </el-button>
        <el-button type="success" plain @click="Output()" style="margin-left:1rem" size="mini">导出衬底</el-button>
        <el-button type="success" plain @click="SelectAll()" style="margin-left:1rem" size="mini">全选本页</el-button>
        <el-button type="danger" plain @click="Clear()" style="margin-left:1rem" size="mini">清空选中</el-button>
        <el-button type="danger" @click="Del()" style="margin-left:1rem" size="mini">删除选中衬底</el-button>
        <el-button type="success" plain @click="GetGIO()" style="margin-left:5rem" size="mini">一键匹配最新投产情况
        </el-button>
      </el-col>
    </div>
    <!-- 主表格 -->
    <div style="float:left;margin-top:1rem;width:100%">
      <pl-table
        use-virtual
        border
        height="460"
        :row-height="28"
        :cell-style="{padding:0,height:'28px'}"
        :header-cell-style="{padding:0,height:'30px'}"
        :data="tableData"
        @row-click="SelectRow"
        :row-class-name="GetTRClass"
        ref="substrTable"
      >
        <pl-table-column v-for="col in cols" :label="col" :prop="col">
          <template slot="header" slot-scope="scope">
            <el-tooltip v-if="col!=='入库日期'" :content="col" placement="top" effect="light" transition="none">
              <el-select
                v-model="screen[col]"
                :placeholder="col"
                filterable
                allow-create
                clearable
                default-first-option
                @change="GetData(1)"
                size="mini"
              >
                <el-option v-if="候选[col]" v-for="item in 候选[col]" :value="item" :label="item"></el-option>
              </el-select>
            </el-tooltip>
            <span v-else>{{ col }}</span>
          </template>
        </pl-table-column>
      </pl-table>
    </div>
    <!-- 分页 -->
    <div style="width: 100%;float:left;margin-top: 1rem;">
      <el-pagination
        @current-change="GetData"
        :current-page="page"
        :page-size="1000"
        layout="total, prev, pager, next, jumper"
        :total="count">
      </el-pagination>
    </div>
    <!-- 导入层 -->
    <el-dialog
      title="导入衬底"
      :visible.sync="inLayer"
      fullscreen
      :close-on-click-modal="true"
      :lock-scroll="true"
    >
      <el-upload
        ref="upload"
        class="upload-demo"
        drag
        :action="uploadURL"
        :on-success="function(res){return AfterU(res)}"
        :before-upload="function(file){ return BeforeU(file)}"
        :data="{token}"
        :headers="{token}"
        :show-file-list="false"
        v-show="step1"
      >
        <i class="el-icon-upload"></i>
        <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
        <div class="el-upload__tip" slot="tip">1. 只能上传 xlsx 格式的文件</div>
        <div class="el-upload__tip" slot="tip">2. 上传前请确保表格的标题行内容准确无误</div>
        <div class="el-upload__tip" slot="tip">3. 上传完成后，程序将只导入第 1 张工作表的数据</div>
      </el-upload>
      <div v-show="step2">
        <pl-table
          use-virtual
          border
          stripe
          height="460"
          :row-height="28"
          :cell-style="{padding:0,height:'28px'}"
          :header-cell-style="{padding:0,height:'30px'}"
          :data="confirmData.rs"
        >
          <pl-table-column label="激光编码" prop="激光编码"></pl-table-column>
        </pl-table>
      </div>
      <div v-show="step3">
        <substr-form :form="form" :options="候选" form-name="ins" ref="ins" @emit-form-data="SubForm"/>
      </div>
      <div style="margin-top:30px">
        <span style="padding:0 0.5rem" v-show="step2">即将导入 {{ confirmData.count }} 条记录</span>
        <el-button type="primary" v-show="step2" @click="Next" plain>下一步</el-button>
        <el-button style="margin-left:150px" type="primary" v-show="step3" @click="DoIns" plain>确认导入</el-button>
        <el-button @click="Cancel" type="danger" plain>取消</el-button>
      </div>
    </el-dialog>
    <!-- 选中确认 -->
    <el-dialog
      title="确认删除"
      :visible.sync="delLayer"
      fullscreen
      :close-on-click-modal="true"
      :lock-scroll="true"
    >
      <pl-table
        use-virtual
        border
        height="460"
        :row-height="28"
        :cell-style="{padding:0,height:'28px'}"
        :header-cell-style="{padding:0,height:'30px'}"
        :data="confirmData.rs"
      >
        <pl-table-column v-for="col in cols" :label="col" :prop="col"></pl-table-column>
      </pl-table>
      <div style="margin-top:30px">
        <span style="padding:0 0.5rem">即将删除 {{ confirmData.count }} 条记录</span>
        <el-button style="margin-left:30px" type="danger" @click="DoDel">确认删除</el-button>
        <el-button @click="Cancel" type="danger" plain>取消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {cancelExIns, doDelEpiSub, getConfirmSub, getEpiSub, getEpiSubGIO, insEpiSub} from "@/http/api";
import {baseUrl} from "@/configs/squareConfig";
import {Loading} from "element-ui";
import SubstrForm from "@/components/SubstrForm";
import {formRequest} from "@/http/formRequest";

let loading
export default {
  name: "Substrate",
  components: {SubstrForm},
  data() {
    return {
      token: this.$cookies.get('token'),
      page: 1,
      count: 0,
      tableData: [],
      cols: ['入库日期', '激光编码', '已投产', '投产结果', '厂家', '尺寸', '镀AlN', '镀AlN厂家', '类型', '备注'],
      screen: {},
      候选: {
        已投产: ['是', '否'],
        投产结果: ['正常', '报废'],
        尺寸: [2, 4, 6],
        镀AlN: ['是', '否'],
        类型: ['平片', 'PSS'],
      },
      inLayer: false,
      uploadURL: baseUrl + '/epi-sub-upload',
      step1: false,
      step2: false,
      step3: false,
      confirmData: {},
      form: {},
      selected: [],
      delLayer: false,
    }
  },
  methods: {
    GetData(page) {
      if (!isNaN(page)) this.page = page
      getEpiSub({
        token: this.token,
        screen: this.screen,
        page: this.page,
      })
        .then(res => {
          if (res.data.code === 'OK') {
            this.tableData = res.data.rs
            this.count = res.data.count
          }
        })
        .catch(err => console.log(err))
    },
    Output() {
      formRequest(baseUrl + '/epi-sub-output', {
        screen: JSON.stringify(this.screen)
      })
    },
    Del() {
      getConfirmSub({
        token: this.token,
        ids: this.selected,
      }).then(res => {
        if (res.data.code === 'OK') {
          this.confirmData = res.data
          this.delLayer = true
        }
      })
        .catch(err => console.log(err))
    },
    DoDel() {
      doDelEpiSub({
        token: this.token,
        ids: this.selected,
      })
        .then(res => {
          if (res.data.code === 'OK') {
            this.$message.success('衬底删除成功')
            this.GetData()
            this.Cancel()
          }
        })
        .catch(err => console.log(err))
    },
    BeforeU(file) {
      loading = Loading.service({fullscreen: true})
    },
    AfterU(res) {
      loading.close()
      if (res.code === 'OK') {
        this.form = {
          已投产: '否',
          投产结果: '-',
          备注: '',
        }
        this.step2 = true
        this.step1 = this.step3 = false
        this.confirmData = res
      }
    },
    Next() {
      this.step1 = this.step2 = false
      this.step3 = true
    },
    DoIns() {
      this.$refs.ins.EmitFormData()
    },
    SubForm(payload) {
      insEpiSub({
        token: this.token,
        form: payload,
        fullPath: this.confirmData.fullPath,
      }).then(res => {
        if (res.data.code === 'OK') {
          this.$message.success('衬底导入成功')
          this.GetData(1)
          this.inLayer = this.step3 = this.step2 = false
          this.step1 = true
        }
      })
        .catch(err => console.log(err))
    },
    Cancel() {
      this.inLayer = this.delLayer = this.step1 = this.step2 = this.step3 = false
      let filePath = this.confirmData.filePath
      let fullPath = this.confirmData.fullPath
      let uploadTime = this.confirmData.uploadTime
      if (filePath && fullPath && uploadTime) {
        cancelExIns({
          token: this.token,
          filePath,
          fullPath,
          uploadTime,
        })
      }
    },
    SelectRow(row) {
      const id = ~~row.id
      const idx = this.selected.findIndex(item => item === id)
      if (idx === -1) this.selected.push(id)
      else this.selected.splice(idx, 1)
      this.$refs.substrTable.setCurrentRow(-1)
    },
    SelectAll() {
      this.tableData.forEach(row => {
        const idx = this.selected.findIndex(id => id === row.id)
        if (idx === -1) this.selected.push(row.id)
      })
    },
    Clear() {
      this.selected = []
    },
    GetGIO() {
      getEpiSubGIO({
        token: this.token
      })
        .then(res => {
          if (res.data.code === 'OK') {
            this.$message.success('投产数据匹配完成')
            this.GetData()
          }
        })
        .catch(err => console.log(err))
    },
  },
  computed: {
    GetTRClass() {
      return ({row}) => {
        const idx = this.selected.findIndex(item => item === row.id)
        if (idx === -1) return ''
        return 'warning-row'
      }
    },
  },
  mounted() {
    this.GetData(1)
  },
}
</script>

<style>
.el-table .warning-row {
  background: yellow !important;
  color: red !important;
  font-weight: bold;
}
</style>
