<template>
  <div>
    <el-form :model="formData">
      <el-form-item label="入库日期" label-width="150px">
        <el-date-picker
          v-model="formData.入库日期"
          type="date"
          placeholder="入库日期"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item v-for="item in ['厂家','尺寸','镀AlN','镀AlN厂家','类型']" :label="item" label-width="150px">
        <el-select
          v-if="item.indexOf('厂家')===-1"
          v-model="formData[item]"
          filterable
          allow-create
          default-first-option
          clearable
          :placeholder="item"
        >
          <el-option v-for="item in (options[item] || [])" :label="item" :value="item"></el-option>
        </el-select>
        <el-input
          v-else
          clearable
          v-model="formData[item]"
          :placeholder="item"
        ></el-input>
      </el-form-item>
      <el-form-item label="备注" label-width="150px">
        <el-input
          clearable
          v-model="formData.备注"
          placeholder="备注"
          type="textarea"
          :rows="4"
        ></el-input>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
export default {
  name: "SubstrForm",
  props: ['formName', 'form', 'options'],
  data() {
    return {
      formData: JSON.parse(JSON.stringify(this.form)),
    }
  },
  methods: {
    EmitFormData() {
      this.formData.formName = this.formName
      this.$emit('emit-form-data', this.formData)
    }
  },
  watch: {
    form: function (val, oldVal) {
      this.formData = JSON.parse(JSON.stringify(val))
    }
  },
  computed: {},
}
</script>

<style scoped>
</style>
